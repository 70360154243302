define("discourse/plugins/discourse-album/discourse/templates/connectors/user-activity-bottom/album", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if siteSettings.album_enabled}}
    <LinkTo
      @route="full-page-search"
      @query={{hash q="with:images @me"}}
    >{{d-icon "image"}} {{i18n "album.title"}}</LinkTo>
  {{/if}}
  */
  {
    "id": "dRYYuHWK",
    "block": "[[[41,[30,0,[\"siteSettings\",\"album_enabled\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@route\",\"@query\"],[\"full-page-search\",[28,[37,2],null,[[\"q\"],[\"with:images @me\"]]]]],[[\"default\"],[[[[1,[28,[35,3],[\"image\"],null]],[1,\" \"],[1,[28,[35,4],[\"album.title\"],null]]],[]]]]],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `siteSettings` property path was used in the `discourse/plugins/discourse-album/discourse/templates/connectors/user-activity-bottom/album.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"link-to\",\"hash\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-album/discourse/templates/connectors/user-activity-bottom/album.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});